import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBn5VEFLR-m0vF1Wl9ZCOPsOjuLHej4ZX0',
  authDomain: 'charlinski-23829.firebaseapp.com',
  projectId: 'charlinski-23829',
  storageBucket: 'charlinski-23829.appspot.com',
  messagingSenderId: '172973682177',
  appId: '1:172973682177:web:0456be015c6f92d5331a00',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
