import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import { db } from '../firebase';
import { collection, doc, getDocs, query, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { AuthUser } from '../auth/AuthContext';

const UserManagement: React.FC = () => {
  const q = query(collection(db, 'user'));
  const [users, setUsers] = useState<AuthUser[]>([]);

  const loadUsers = async () => {
    const querySnapshot = await getDocs(q);
    const dbUsers: AuthUser[] = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        uid: docData.uid,
        name: docData.name,
        email: docData.email,
        role: docData.role,
        active: docData.active,
      };
    });
    setUsers(dbUsers);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const changeActiveState = async (userId: string, active: boolean) => {
    const userRef = doc(db, 'user', userId);
    await updateDoc(userRef, { active: !active });
    await loadUsers();
  };

  return (
    <Box paddingTop='64px'>
      <Box maxWidth='500px' marginX='auto'>
        <List>
          {users.map((user) => (
            <ListItem
              key={user.uid}
              secondaryAction={<Button onClick={() => changeActiveState(user.uid, user.active)}>{user.active ? 'Deaktivieren' : 'Aktivieren'}</Button>}
            >
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default UserManagement;
