import { Route, Routes } from 'react-router-dom';
import CreateBlogEntry from '../pages/CreateBlogEntry';
import Home from '../pages/Home';
import Login from '../pages/Login';
import UserManagement from '../pages/UserManagement';
import AdminPage from './AdminPage';
import PrivatePage from './PrivatePage';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <PrivatePage>
            <Home />
          </PrivatePage>
        }
      />
      <Route path='login' element={<Login />} />
      <Route
        path='create'
        element={
          <PrivatePage>
            <AdminPage>
              <CreateBlogEntry />
            </AdminPage>
          </PrivatePage>
        }
      />
      <Route
        path='users'
        element={
          <PrivatePage>
            <AdminPage>
              <UserManagement />
            </AdminPage>
          </PrivatePage>
        }
      />
    </Routes>
  );
};

export default Router;
