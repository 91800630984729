import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Google as GoogleIcon } from "@mui/icons-material";
import bgImg from "../assets/img/intro-bg.jpg";

const Login: React.FC = () => {
  const { user, login, loading } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user !== null) {
      navigate("/");
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="#fff" variant="h3">
              Charlinski goes Taiwan
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              startIcon={<GoogleIcon />}
              variant="contained"
              onClick={login}
            >
              Login with Google
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
