import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { db, storage } from '../firebase';
import { DateTime } from 'luxon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Comment from './Comment';
import WriteComment from './WriteComment';
import { useForm } from 'react-hook-form';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useAuthContext } from '../auth/AuthContext';

export interface NewCommentI {
  comment: string;
  userName: string;
  date: Date;
}
export interface CommentI {
  comment: string;
  userName: string;
  date: { seconds: number; nanoseconds: number };
}
export interface BlogEntryI {
  id: string;
  text: string;
  fileName: string;
  date: { seconds: number; nanoseconds: number };
  comments: CommentI[];
}

interface FormData {
  comment: string;
}

interface Props {
  blogEntryData: BlogEntryI;
  addComment: (blogEntryId: string, comment: CommentI) => void;
}

const BlogEntry: React.FC<Props> = ({ blogEntryData, addComment }) => {
  const pathReference = ref(storage, `/files/${blogEntryData.fileName}`);
  const [imagePath, setImagePath] = useState('');
  const { user } = useAuthContext();

  useEffect(() => {
    getDownloadURL(pathReference).then((url) => {
      setImagePath(url);
    });
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      comment: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    const blogEntryRef = doc(db, 'blogEntries', blogEntryData.id);
    const newComment: NewCommentI = {
      date: new Date(),
      comment: data.comment,
      userName: user?.name || 'Unbekannt',
    };
    await updateDoc(blogEntryRef, { comments: arrayUnion(newComment) });
    addComment(blogEntryData.id, { ...newComment, date: { seconds: Math.round(newComment.date.getTime() / 1000), nanoseconds: 0 } });
    setValue('comment', '');
  };

  return (
    <Card>
      <img width={'100%'} src={imagePath} />
      <CardContent>
        <Typography variant='body2' textAlign='right'>
          {DateTime.fromSeconds(blogEntryData.date.seconds).toFormat('dd.MM.yyyy')}
        </Typography>
        <Typography>{blogEntryData.text}</Typography>
      </CardContent>
      <Divider />
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <Typography>Kommentare</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {blogEntryData.comments.map((comment) => (
              <Comment comment={comment} key={blogEntryData.comments.indexOf(comment)} />
            ))}
            {blogEntryData.comments.length === 0 && (
              <Box textAlign='center'>
                <Typography variant='body2'>Keine Kommentare vorhanden</Typography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <WriteComment control={control} handleSubmit={handleSubmit(onSubmit)} />
    </Card>
  );
};

export default BlogEntry;
