import { Box } from '@mui/material';
import BlogEntryForm from '../components/BlogEntryForm';

const CreateBlogEntry: React.FC = () => {
  return (
    <Box padding={1} maxWidth='360px' marginX='auto' paddingTop='64px'>
      <BlogEntryForm />
    </Box>
  );
};

export default CreateBlogEntry;
