import { Box, List, ListItem, Fab } from '@mui/material';
import BlogEntry, { BlogEntryI, CommentI } from '../components/BlogEntry';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { collection, DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Role, useAuthContext } from '../auth/AuthContext';

const PAGE_SIZE = 5;

const Home: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const blogEntriesRef = collection(db, 'blogEntries');
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>();
  const [blogEntries, setBlogEntries] = useState<BlogEntryI[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const handleCreateBlogClick = () => {
    navigate('/create');
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    const initialData = query(blogEntriesRef, orderBy('date', 'desc'), limit(PAGE_SIZE));
    const documentSnapshots = await getDocs(initialData);
    setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    appendToData(documentSnapshots.docs);
  };

  const fetchMoreData = async () => {
    const next = query(blogEntriesRef, orderBy('date', 'desc'), startAfter(lastVisible), limit(PAGE_SIZE));
    const documentSnapshots = await getDocs(next);
    setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
    if (documentSnapshots.docs.length > 0) {
      appendToData(documentSnapshots.docs);
    } else {
      setHasMore(false);
    }
  };

  const appendToData = (docs: QueryDocumentSnapshot<DocumentData>[]) => {
    const data: BlogEntryI[] = docs.map((doc) => {
      const docData = doc.data();
      return { id: doc.id, text: docData.text, fileName: docData.fileName, date: docData.date, comments: docData.comments };
    });
    setBlogEntries(blogEntries.concat(data));
  };

  const handleAddComment = (blogEntryId: string, comment: CommentI) => {
    setBlogEntries(
      blogEntries.map((entry) => {
        if (entry.id === blogEntryId) {
          entry.comments.push(comment);
        }
        return entry;
      }),
    );
  };

  return (
    <>
      <Box padding={2} paddingTop='64px' sx={{ backgroundColor: '#e6e6e6' }}>
        <List sx={{ width: '100%', maxWidth: 500, marginX: 'auto' }}>
          {blogEntries.length > 0 ? (
            <InfiniteScroll dataLength={blogEntries.length} next={fetchMoreData} hasMore={hasMore} loader={<h4>Loading...</h4>}>
              {blogEntries.map((blogEntry) => (
                <ListItem key={blogEntries.indexOf(blogEntry)}>
                  <BlogEntry blogEntryData={blogEntry} addComment={handleAddComment} />
                </ListItem>
              ))}
            </InfiniteScroll>
          ) : (
            <div>No Data</div>
          )}
        </List>
      </Box>
      {user && user.role === Role.editor && (
        <Fab color='primary' onClick={handleCreateBlogClick} aria-label='add' sx={{ position: 'fixed', bottom: 10, right: 10 }}>
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

export default Home;
