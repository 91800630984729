import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#ff9100',
    },
    background: {
      default: '#f3f3f3',
    },
  },
});
