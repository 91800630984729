import { signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';

export enum Role {
  editor = 'Editor',
  reader = 'Reader',
}

export interface AuthUser {
  uid: string;
  name: string;
  email: string;
  role: Role;
  active: boolean;
}

interface AuthState {
  isLoggedIn: boolean;
  login: () => void;
  user: AuthUser | null;
  loading: boolean;
  logout: () => void;
}

interface Props {
  children: JSX.Element;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [authStateUser, authStateLoading] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const navigate = useNavigate();

  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(authStateLoading);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const loadUser = useCallback(async (): Promise<AuthUser | null> => {
    let authUser: AuthUser | null = null;
    if (authStateUser) {
      const userRef = doc(db, 'user', authStateUser.uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const dbUser = docSnap.data();
        authUser = {
          uid: dbUser.uid,
          name: dbUser.name,
          email: dbUser.email,
          role: dbUser.role || Role.reader,
          active: dbUser.active || false,
        };
      } else {
        // create user in db if not exists
        const newUser: AuthUser = {
          uid: authStateUser.uid,
          name: authStateUser.displayName || '',
          email: authStateUser.email || '',
          role: Role.reader,
          active: false,
        };
        await setDoc(doc(db, 'user', authStateUser.uid), newUser);
        authUser = newUser;
      }
    }
    return authUser;
  }, [authStateUser]);

  useEffect(() => {
    if (!authStateLoading && authStateUser) {
      loadUser().then((authUser) => {
        setUser(authUser);
        setLoading(false);
        setIsLoggedIn(true);
      });
    }
    if (!authStateLoading && authStateUser === null) {
      setLoading(false);
    }
  }, [authStateLoading, authStateUser, loadUser]);

  const login = useCallback(async () => {
    await signInWithGoogle();
    const authUser = await loadUser();
    setUser(authUser);
    setLoading(false);
    setIsLoggedIn(true);
    navigate('/');
  }, [loadUser, navigate, signInWithGoogle]);

  const logout = useCallback(async () => {
    await signOut(auth);
    setUser(null);
    setIsLoggedIn(false);
    navigate('/login');
  }, [navigate]);

  const context = useMemo<AuthState>(
    () => ({
      isLoggedIn,
      login,
      user,
      loading,
      logout,
    }),
    [isLoggedIn, login, user, loading, logout],
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export function useAuthContext(): AuthState {
  const ctx = useContext(AuthContext);

  if (ctx === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return ctx;
}
