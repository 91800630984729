import { Box, Button, Typography } from "@mui/material";
import { useRef } from "react";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

interface Props {
  setFile: (file: File) => void;
  file: File | null;
}

const UploadImage: React.FC<Props> = ({ setFile, file }) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    setFile(file);
  };

  const uploadFile = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: 1,
          maxWidth: "100%",
          width: "320px",
          height: "240px",
          marginX: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {file ? (
          <img
            width="100%"
            height="100%"
            src={URL.createObjectURL(file)}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            alt="upload"
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              border: "3px dashed",
              borderColor: "#e0e0e0",
              borderRadius: "8px",
              height: "100%",
            }}
          >
            <Typography>Kein Foto ausgewählt</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Button onClick={uploadFile}>Bild auswählen</Button>
      </Box>
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default UploadImage;
