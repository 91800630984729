import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";

export interface UploadBlogEntryI {
  text: string;
  fileName: string;
  date: Date;
  comments: [];
}

const resizeImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1920,
      1920,
      "JPEG",
      80,
      0,
      (uri) => {
        if (uri instanceof File) {
          resolve(uri);
        } else {
          reject("Error");
        }
      },
      "file"
    );
  });
};

const createBlogEntryInDB = async (fileName: string, text: string) => {
  const blogEntry: UploadBlogEntryI = {
    text,
    fileName,
    date: new Date(),
    comments: [],
  };
  await addDoc(collection(db, "blogEntries"), blogEntry);
};

export const uploadImage = async (image: File, text: string): Promise<void> => {
  const resizedImage = await resizeImage(image);
  const fileName = `${uuidv4()}-${image.name}`;
  const storageRef = ref(storage, `/files/${fileName}`);
  await uploadBytes(storageRef, resizedImage);
  await createBlogEntryInDB(fileName, text);
};
