import { Box, Button, Grid, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { uploadImage } from '../services/uploadImage';
import UploadImage from './UploadImage';

interface FormData {
  text: string;
}

const BlogEntryForm: React.FC = () => {
  const [image, setImage] = useState<File | null>(null);

  const navigate = useNavigate();

  const handleCancelClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      text: '',
    },
  });

  const setImageToUpload = (image: File) => {
    setImage(image);
  };

  const onSubmit = async (data: FormData) => {
    if (!image) {
      return;
    }
    await uploadImage(image, data.text);
    navigate('/');
  };
  return (
    <Box paddingTop={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UploadImage setFile={setImageToUpload} file={image} />
          </Grid>
          <Grid item xs={12}>
            <Controller name='text' control={control} render={({ field }) => <TextField multiline label='Nachricht' minRows={3} fullWidth {...field} />} />
          </Grid>
          <Grid item container justifyContent='space-between'>
            <Grid item>
              <Button color='error' onClick={handleCancelClick}>
                Abbrechen
              </Button>
            </Grid>
            <Grid item>
              <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                Beitrag erstellen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BlogEntryForm;
