import { Box, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Role, useAuthContext } from "../auth/AuthContext";
import GroupIcon from "@mui/icons-material/Group";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Nav: React.FC = () => {
  const { logout, user } = useAuthContext();
  const navigate = useNavigate();

  const handleToUserManagement = useCallback(() => {
    navigate("/users");
  }, [navigate]);

  const handleToHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          {user && user.role === Role.editor && (
            <IconButton onClick={handleToUserManagement} color="inherit">
              <GroupIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: "pointer" }}
            onClick={handleToHome}
          >
            Meine Reise
          </Typography>
          <IconButton onClick={logout} color="inherit">
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Nav;
