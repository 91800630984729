import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import Nav from '../components/Nav';

interface Props {
  children: JSX.Element;
}

const PrivatePage: React.FC<Props> = ({ children }) => {
  const { loading, isLoggedIn, user } = useAuthContext();

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <>
      {isLoggedIn ? (
        user && user.active ? (
          <>
            <Nav />
            {children}
          </>
        ) : (
          <>
            <Nav />
            <Box
              sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography>Du bist noch nicht freigeschaltet, bitte warte darauf, dass ein Admin dich freischaltet</Typography>
            </Box>
          </>
        )
      ) : (
        <Navigate to='/login' replace={true} />
      )}
    </>
  );
};

export default PrivatePage;
