import { Box, Grid, Typography } from '@mui/material';
import { CommentI } from './BlogEntry';
import { DateTime } from 'luxon';

interface Props {
  comment: CommentI;
}

const Comment: React.FC<Props> = ({ comment }) => {
  return (
    <Box padding={1}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='body2'>{comment.userName}</Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Typography variant='body2'>{DateTime.fromSeconds(comment.date.seconds).toFormat('dd.MM.yyyy')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{comment.comment}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Comment;
