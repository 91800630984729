import { ThemeProvider } from "@mui/material";
import "./App.css";
import { AuthProvider } from "./auth/AuthContext";
import Router from "./modules/Router";
import { theme } from "./theme";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
