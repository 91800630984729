import { Backdrop, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Role, useAuthContext } from '../auth/AuthContext';
import Nav from '../components/Nav';

interface Props {
  children: JSX.Element;
}

const AdminPage: React.FC<Props> = ({ children }) => {
  const { loading, isLoggedIn, user } = useAuthContext();

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <>
      {isLoggedIn && user && user.role === Role.editor ? (
        <>
          <Nav />
          {children}
        </>
      ) : (
        <Navigate to='/' replace={true} />
      )}
    </>
  );
};

export default AdminPage;
