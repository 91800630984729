import { Box, TextField, Button, Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

interface Props {
  handleSubmit: () => void;
  control: Control<
    {
      comment: string;
    },
    any
  >;
}

const WriteComment: React.FC<Props> = ({ handleSubmit, control }) => {
  const [commentOpen, setCommentOpen] = useState(false);

  const handleOpenComment = useCallback(() => {
    setCommentOpen(true);
  }, []);

  const handleCloseComment = useCallback(() => {
    setCommentOpen(false);
  }, []);

  const handleSendComment = useCallback(() => {
    handleSubmit();
    setCommentOpen(false);
  }, [handleSubmit]);

  return (
    <Box padding={1}>
      {commentOpen ? (
        <Box paddingBottom={1}>
          <form onSubmit={handleSubmit}>
            <Controller
              name='comment'
              control={control}
              rules={{ required: true }}
              render={({ field }) => <TextField variant='filled' multiline label='Kommentar schreiben' fullWidth {...field} />}
            />
            <Grid container>
              <Grid item xs={6}>
                <Button color='error' onClick={handleCloseComment}>
                  Abbrechen
                </Button>
              </Grid>
              <Grid item xs={6} textAlign='right'>
                <Button onClick={handleSendComment}>Kommentieren</Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      ) : (
        <Box textAlign='center'>
          <Button onClick={handleOpenComment}>Kommentar schreiben</Button>
        </Box>
      )}
    </Box>
  );
};

export default WriteComment;
